@font-face {
  font-family: obrige;
  src: url("../assets/fonts/La Obrige.otf");
}

@font-face {
  font-family: lora;
  src: url("../assets/fonts/Lora/Lora-VariableFont_wght.ttf");
}

@font-face {
  font-family: yellowtail;
  src: url("../assets/fonts/Yellowtail-Regular.ttf");
}

@font-face {
  font-family: publicsans;
  src: url("../assets/fonts/Public_Sans/PublicSans-VariableFont_wght.ttf");
}

.obrige {
  font-family: "obrige";
}

.lora {
  font-family: "lora";
}

.yellowtail {
  font-family: "yellowtail";
}

.publicsans {
  font-family: "publicsans";
}
