.aboutbg {
  background-image: url("../assets/Image/about\ qro\ background.png");
}

.swiper-wrapper {
  transition-timing-function: linear !important;
}

.containertextshadow {
  box-shadow: rgba(129, 129, 129, 0.56) 0px 0px 70px 3px;
}

html {
  scroll-behavior: smooth;
}